<template>
  <div
    v-show="this.$store.state.layout !== 'landing'"
    class="card card-plain shadow-none"
    id="sidenavCard"
  >
    <div class="p-3 card-body text-center w-100 pt-0">
      <img
        class="w-50 mx-auto"
        src="@/assets/img/illustrations/icon-documentation.svg"
        alt="sidebar_illustration"
      />
      <h6 v-if="this.$store.state.isRTL" class="mb-0 text-dark up">
        Need Help
      </h6>
      <h6 v-else class="mb-0 text-dark up">Need Help ?</h6>
      <p v-if="this.$store.state.isRTL" class="text-xs font-weight-bold">
        Assistencia
      </p>
      <p v-else class="text-xs font-weight-bold">Please check our docs</p>
    </div>
   
    <router-link to="docs" class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
     <a href=# class="mb-3 btn btn-dark btn-sm w-100">
      Documentation
     </a>
    </router-link>

  </div>
</template>
<script>
import img from "../../assets/img/illustrations/icon-documentation.svg";

export default {
  name: "sidenav-card",
  props: ["textPrimary", "textSecondary", "iconClass"],
  data() {
    return {
      img
    };
  }
};
</script>
