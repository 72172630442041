<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100"
                style="background-image: url('/img/bg.jpg');
          background-size: cover;">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Docs</h4>
                  <p class="mb-0"></p>

                  <div class="input-group">
                    <span class="input-group-text text-body">
                      <i class="fas fa-search" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="
                        this.$store.state.isRTL ? 'Procurar...' : 'Search...'
                      "
                      @keyup.prevent="search($event.target.value)"
                    />
                  </div>
                  <br><br>
                  <div id="search_results">

                    <ul v-for="sr in search_results" :key="sr.id">
                      <li>{{sr.desc}}</li>
                    </ul>

                  </div>
                </div>
                <div class="card-body">
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>

  import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
  import AppFooter from "@/examples/PageLayout/Footer.vue";

  const body = document.getElementsByTagName("body")[0];

	export default ({
    name: "Docs",
    components: {
      Navbar,
      AppFooter,
    },
    data() {
      return {
        search_results:[{id:"1", desc:"API Doumentation"},{id:"2", desc:"Agent Master Data"},{id:"3", desc:"IFG MAPD"}]
      }
    },
    methods: {
    },
    created() {
      this.$store.state.hideConfigButton = true;
      this.$store.state.showNavbar = false;
      this.$store.state.showSidenav = false;
      this.$store.state.showFooter = false;
      body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
      this.$store.state.hideConfigButton = false;
      this.$store.state.showNavbar = true;
      this.$store.state.showSidenav = true;
      this.$store.state.showFooter = true;
      body.classList.add("bg-gray-100");
    },

	})

</script>
