// import bootstrap from "bootstrap";

// // initialization of Tooltips
// export default function setTooltip() {
//   var tooltipTriggerList = [].slice.call(
//     document.querySelectorAll('[data-bs-toggle="tooltip"]')
//   );
//   // eslint-disable-next-line no-unused-vars
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl);
//   });
// }

import { Tooltip } from 'bootstrap';

// Initialize tooltips
export default function setTooltip() {
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tooltipTriggerEl => {
    new Tooltip(tooltipTriggerEl);
  });
}