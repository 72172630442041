<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{this.company}} FYM Analytics</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
           
                <div class="card-header pb-0">
                  <div class="d-flex align-items-center">
                    <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>                    

                  </div>
                </div>

                <div ref="vizContainer" style="width: 100%; height: 800px;"></div>
                
              </div>
              <br><br><br><br><br><br>
            </div>
          </div>
        
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

const company = sessionStorage.getItem("company");
var message = "";
var filter = "";

export default {
  name: 'TableauEmbed',
  data() {
    return {
      showMenu: false,
      tableauSrc: 'https://prod-useast-b.online.tableau.com/#/site/john3a119af794/workbooks/1981056/views',
      token: null,
      siteId: null
    };
  },
  methods: {
    async authenticateWithPAT() {
      const response = await fetch('/apis/tableau_auth2', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('Authenticated successfully:', data);
        //console.log('Authenticated successfully:', data.credentials.token);
        //console.log('Authenticated successfully:', data.credentials.site.id);
        //console.log('Authenticated successfully:', data.credentials.site.contentUrl);

        this.token = data.credentials.token; // Store the token
        this.siteId = data.credentials.site.id; // Store the site ID if required
        //this.contentUrl = data.credentials.site.contentUrl; // Store the site ID if required
        return data;
      } else {
        console.error('Failed to authenticate');
        return null;
      }
    },
    async loadTableauViz() {
      const authData = await this.authenticateWithPAT();
      if (authData) {
        const { token, siteId} = authData;
        const vizUrl = 'https://prod-useast-b.online.tableau.com/t/john3a119af794/views/AgentDailySurvey/AgentSurveyDaily'
        const options = {
          width: '100%',
          height: '800px',
          hideTabs: true,
          hideToolbar: true,
          onFirstInteractive: function () {
            console.log('Tableau Viz Loaded');
          },
          token: this.token,  // Pass the session token here
        };

        new tableau.Viz(this.$refs.vizContainer, vizUrl, options);
      }
    }
  },
  components: { Navbar, ArgonButton },
  mounted() {
    this.loadTableauViz();
  }
};
</script>

<style scoped>
.tableau-embed {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>