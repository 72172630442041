<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">

      <li v-if="isFYMAdmin" id="contracts_link" class="nav-item">
        <sidenav-item
          url="/Contracts"
          class='active'
          navText='Contracts'
        >
          <template v-slot:icon>
            <i class="ni ni-books text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> 

      <li v-if="isFYMAdmin" id="carriers_link" class="nav-item">
        <sidenav-item
          url="/Carriers"
          class='active'
          navText='Carriers'
        >
          <template v-slot:icon>
            <i class="ni ni-building text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> 

      <li v-if="isFYMAdmin" id="agencies_link" class="nav-item">
        <sidenav-item
          url="/Agencies"
          class='active'
          navText='Agencies'
        >
          <template v-slot:icon>
            <i class="ni ni-box-2 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> 

      <li v-if="isAgencyAdmin" id="agency_admin_link" class="nav-item">
        <sidenav-item
          url="/AgencyAdmins"
          class='active'
          navText='Agency Admins'
        >
          <template v-slot:icon>
            <i class="ni ni-app text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="isAgencyAdmin" id="agent_managers_link" class="nav-item">
        <sidenav-item
          url="/AgentManagers"
          class='active'
          navText='Agent Managers'
        >
          <template v-slot:icon>
            <i class="ni ni-app text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="isFYMAdmin || isAgencyAdmin || isAgentManager" id="agency_rates_link" class="nav-item">
        <sidenav-item
          url="/AgencyRates"
          class='active'
          navText='Agency Rates'
        >
          <template v-slot:icon>
            <i class="ni ni-money-coins text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="isFYMAdmin || isAgencyAdmin || isAgentManager || isAgent" id="agents_link" class="nav-item">
        <sidenav-item
          url="/Agents"
          class='active'
          navText='Agents'
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-info text-sm opacity-10"></i>            
          </template>
        </sidenav-item>
      </li> 

      <li id="loans_link" class="nav-item">
        <sidenav-item
          url="/Loans"
          class='active'
          navText='Loans'
        >
          <template v-slot:icon>
            <i class="ni ni-building text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li id="repayments_link" class="nav-item">
        <sidenav-item
          url="/Repayments"
          class='active'
          navText='Repayments'
        >
          <template v-slot:icon>
            <i class="ni ni-building text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li id="Dashboard_link" class="nav-item">
        <sidenav-item
          url="/dashboard"
          class='active'
          navText='Agency Dashboard'
        >
          <template v-slot:icon>
            <i class="ni ni-chart-pie-35 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li id="Xano_link" class="nav-item">
        <sidenav-item
          url="/Xano"
          class='active'
          navText='IFG MAPD Data'
        >
          <template v-slot:icon>
            <i class="ni ni-archive-2 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li id="Analytics_link" class="nav-item" hidden>
        <sidenav-item
          url="/Analytics"
          class='active'
          navText='Analytics'
        >
          <template v-slot:icon>
            <i class="ni ni-chart-bar-32 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li id="profile_link" class="nav-item">
        <sidenav-item
          url="/profile"
          class='active'
          navText='Profile'
        >
          <template v-slot:icon>
            <i class="ni ni-circle-08 text-info text-sm opacity-10"></i>            
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      textPrimary="Need Help?"
      textSecondary="Please check our docs"
    />
  </div>

</template>

<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

const company = sessionStorage.getItem("company");

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "FYM Financial",
      controls: "dashboardsExamples",
      isActive: "active",
      role: sessionStorage.getItem('role'),
      profile: sessionStorage.getItem('profile'),
      company
    };
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  components: {
    SidenavItem,
    SidenavCard
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  created() {
  },
};
</script>
