import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ArgonDashboard from "./argon-dashboard";
import vue3GoogleLogin from 'vue3-google-login'
import "./assets/css/nucleo-icons.css";
import "./assets/css/fym-custom.css";
import "./assets/css/fontawesome.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);

appInstance.use(vue3GoogleLogin, {
  clientId: '823666176651-94nlrhp8jsbal4bt0t5nvlgfqt4lopq3.apps.googleusercontent.com'
})

appInstance.config.globalProperties.$version = '2.0.0';
appInstance.config.globalProperties.$node_env = process.env.VUE_APP_NODE_ENV;
appInstance.config.globalProperties.$hostname = process.env.VUE_APP_HOSTNAME;
appInstance.config.globalProperties.$api_url = process.env.VUE_APP_API_URL;

appInstance.mount("#app");