<template>
  <!-- -------- FooterLoggedin ------- -->
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
          <img src="/img/US.png" alt="Made in the USA" style="width: 10px; height: 10px; margin-right: 5px;">
            ©
            {{ new Date().getFullYear() }} teamfym.com
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <p
                class="nav-link pe-0 text-muted"
                >v{{ this.$version }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
